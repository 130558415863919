<template>
  <div class="pages">
    <div class="oper_box">
      <div class="btns" @click="handlePopups()">
        <div class="el-icon el-icon-circle-plus-outline"></div>
        <div>添加角色</div>
      </div>
      <div class="btns" @click="operDerive">
        <div class="el-icon el-icon-delete"></div>
        <div>批量删除</div>
      </div>
    </div>
    <div class="tabless">
      <div style="width: 100%">
        <el-table
          ref="multipleTable"
          :data="roleList"
          tooltip-effect="dark"
          style="height: 100%; width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            align="center"
            prop="name"
            label="角色名称"
            width="200"
          />
          <el-table-column
            align="center"
            prop="rules"
            label="角色权限"
            min-width="150"
          />
          <el-table-column
            align="center"
            prop="createtime"
            label="创建时间"
            min-width="70"
          />
          <el-table-column
            label="操作"
            align="center"
            fixed="right"
            width="180"
          >
            <template slot-scope="scope">
              <div
                class="handle_box"
                style="display: flex; justify-content: space-around"
              >
                <div
                  class="handle_btn edit"
                  @click="handlePopups(scope.row.id)"
                >
                  编辑
                </div>
                <div class="handle_btn" @click="handleDelete(scope.row.id)">
                  删除
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      title="添加角色"
      width="30%"
      center
      :visible.sync="dialogFormVisible"
    >
      <el-form :model="form">
        <el-form-item label="角色名称: ">
          <el-input
            v-model="form.name"
            placeholder="角色名称"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item
          v-for="(item, index) in dialogList"
          :key="index"
          :label="item.title"
          width="100"
        >
          <el-select
            v-model="roleSelect[index]"
            :placeholder="'请选择' + item.title"
            @change="changeRole(item.keys, roleSelect[index])"
          >
            <el-option
              v-for="ite in sheList"
              :key="ite.value"
              :label="ite.label"
              :value="ite.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      roleList: [],
      dialogFormVisible: false,
      form: {
        name: '',
        data: [],
        id: '',
      },
      sheList: [
        { value: 0, label: '否' },
        { value: 1, label: '是' },
      ],
      dialogList: [],
      t: '',
      roleSelect: [],
      switchgear: [],
      id: [],
    }
  },
  mounted() {
    // 获取表格列表数据
    this.getTypeList()
    this.getDialogList()
  },
  methods: {
    async submit() {
      const res = await this.$http.post('group/handle', this.form)
      if (res.code !== 1) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.getTypeList()

      this.dialogFormVisible = false
    },
    // 弹框数据回显
    changeRole(val, num) {
      console.log(val, num, 'fff')
      console.log(this.roleSelect, 'this.roleSelect')
      if (num == 1) {
        this.switchgear.push(val)
      }
      if (num == 0) {
        var newArray = this.switchgear.filter(function (value) {
          return value !== val // 过滤掉值为 3 的元素
        })
        this.switchgear = newArray
      }
      console.log(this.switchgear, '1111111')

      this.form.data = this.switchgear
    },
    // 数据列表
    async getDialogList() {
      const res = await this.$http.post('group/menu')
      this.dialogList = res.data
      for (var i = 0; i < this.dialogList.length; i++) {
        this.roleSelect.push('')
      }
    },
    // 修改
    async handlePopups(id) {
      this.form.id = id
      var newswitchgear = []

      const res = await this.$http.post('group/menu', { group_id: id })
      const restwo = await this.$http.post('group', { id: id })
      this.form.name = restwo.data.name
      for (var i = 0; i < res.data.length; i++) {
        this.roleSelect[i] = res.data[i].value
        if (res.data[i].value == 1) {
          newswitchgear.push(res.data[i].keys)
        }
      }
      this.switchgear = newswitchgear
      console.log(this.switchgear, 'this.newewswitchgear')
      console.log(this.roleSelect, 'roleSelect[index]')
      this.dialogFormVisible = true
    },
    operDerive() {},

    async getTypeList() {
      const res = await this.$http.post('group')
      this.roleList = res.data.list
    },

    handleSelectionChange(val) {
      var arr = []
      this.multipleSelection = val
      for (var i = 0; i < this.multipleSelection.length; i++) {
        arr.push(this.multipleSelection[i].id)
      }
      this.id = arr.join()
    },
    // 批量删除
    operDerive() {
      this.$confirm('您确定要删除此账号吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const res = await this.$http.post('group/del', { id: this.id })
          if (res.code !== 1) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.getTypeList()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 单独删除
    handleDelete(id) {
      console.log(id, 'fff')
      this.$confirm('您确定要删除此账号吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const res = await this.$http.post('group/del', { id: id })
          if (res.code !== 1) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.getTypeList()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    demo_open(data) {
      this.form.pid = data.id
      this.dialogFormVisible = true
    },
  },
}
</script>
<style lang="scss" scoped>
.pages {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tabless {
  margin-top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
// 顶部按钮
.oper_box {
  display: flex;
  justify-content: flex-end;

  .btns {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
    margin-left: 25px;
    background: #233b67;
    border-radius: 5px;
    border: 1px solid #4486ff;
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    cursor: pointer;
    .el-icon {
      font-size: 20px;
      margin-right: 5px;
    }
  }
}

::v-deep .el-form-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-form-item__label {
  width: 90px;
}
::v-deep .el-color-picker__icon,
.el-input,
.el-textarea {
  width: 300px;
}
::v-deep .el-input__inner {
  width: 300px;
}
::v-deep .el-table td.el-table__cell {
  border: 0 !important;
}
::v-deep .el-table .cell {
  color: #fff;
}

::v-deep .el-table th.el-table__cell {
  // background: rgba(0, 0, 0, 0) !important;
  background: #3a66b8 !important;
}
::v-deep .el-table__cell {
  // background: #3a66b8 !important;
  background: transparent !important;
  cursor: pointer;
}
::v-deep .el-table {
  // background: rgba(0, 0, 0, 0) !important;
  background: #3a66b8 !important;
  // width: 1300px !important;
}
::v-deep .el-table tr {
  // background: rgba(0, 0, 0, 0) !important;
  background: #3a66b8 !important;
}
::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: 1px solid #4486ff !important;
}
::v-deep .el-table th.el-table__cell.is-leaf {
  border: 1px solid #4486ff !important;
}
::v-deep .el-table::before {
  width: 0 !important;
}
</style>
